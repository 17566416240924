body {
  margin: 0;
  font-family: -apple-system, "HiraKakuProN-W6", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-image: url(bg.png) !important;
  background-size: 145px;

}

.clearfix::after {
   content: "";
   display: block;
   clear: both;
}
